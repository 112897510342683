.Indices-page{
    height: 100vh;
    background-color: #fff;
    margin-bottom: 20%;
}

@media screen and (min-width: 900px) {
    .Indices-page{
        background-color: #EDF1F6;
        margin-bottom: 0;
    }
}
.graph-container {
    transition: filter 0.3s ease-in-out;
}

.blurred {
    filter: blur(8px); /* Apply blur effect */
    pointer-events: none; /* Prevent interaction */
}

.lock-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}
.lock-overlay img{
    width: 40px;
}
@media screen and (max-width: 900px){
    .lock-overlay img{
        width: 30px;
    }
}
.blurred-graph {
    filter: blur(5px);
    transition: filter 0.3s ease-in-out;
}
