.mai_div_menu{
  background-color: white; 
  /* width: 800px; */
  /* border-radius: 50px; */
  
}
.frameChild_menulist {
    position: relative;
    border-radius: 6px;
    width: 33.63px;
    height: 33.63px;
  }
  .crypto_menulist {
    position: relative;
    letter-spacing: -0.01em;
    color: #727376;
  }
  .frameContainer_menulist,
  .frameGroup_menulist,
  .frameWrapper_menulist {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
   
  }
  .frameGroup_menulist {
    align-items: center;
    
    gap: 15.17px;
     background-color: white ;
  }
  .frameContainer_menulist,
  .frameWrapper_menulist {
    align-self: stretch;
    padding: px 0;
    align-items: flex-start;
    
  }
  .frameWrapper_menulist {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.11); */
    flex-direction: column;
    padding: 0px 26px;
    display: flex;
    
  }
  .frameIcon_menulist {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    
    
  }
  .portfolio {
    flex: 1;
    position: relative;
   
  }
  .frameParent2 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    
  }
  .frameWrapper1,
  .frameWrapper3 {
    align-self: stretch;
    border-radius: 8px 0 0 8px;
    flex-direction: row;
    padding: 14px;
    align-items: center;
    
  }
  .frameWrapper1 {
    display: flex;
    justify-content: flex-start;
    
  }
  .frameWrapper3 {
    background-color: white;
   
    
    
  }
  .frameParent1,
  .frameWrapper3,
  .frameWrapper5 {
    display: flex;
    justify-content: flex-start;
    
  }
  .frameParent1 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
   
  }
  .frameWrapper5 {
    margin-top: 150px;
    border-radius: 8px;
    background-color: white;
    width: 240px;
    flex-direction: row;
    padding: 14px;
    box-sizing: border-box;
    align-items: center;
    color: #fff;
  }
  
  .logoutBtn_menulist:hover {
    background: linear-gradient(90deg, #1877F2 0%, #519DFF 100%); 
    color:white!important; 
    font-weight:bold;
  }
  .frameDiv,
  .frameParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .frameDiv {
    align-self: stretch;
    flex: 1;
    padding: 26px 0 26px 26px;
    justify-content: space-between;
    font-size: 14px;
    color: #727376;
  }
  .frameParent {
    border-radius: 0 10px 10px 0;
    background-color: white;
    
    box-sizing: border-box;
    width: 292px;
    height: 1024px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-start;
  }
  .cryptocurrencyList2 {
    position: relative;
    font-weight: 600;
  }
  .frameIcon5 {
    width: 19px;
    height: 19px;
    overflow: hidden;
    flex-shrink: 0;
    opacity: 0.44;
  }
  .frameIcon5,
  .text {
    position: relative;
  }
  .cryptocurrencyListParent,
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .search {
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-sizing: border-box;
    width: 239px;
    padding: 13px 19px;
    justify-content: flex-start;
    gap: 10px;
    text-align: center;
    font-size: 14px;
    color: #727376;
  }
  .cryptocurrencyListParent {
    align-self: stretch;
    justify-content: space-between;
  }
  .name {
    position: relative;
    
  }
  .nameWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    align-items: center;
    justify-content: flex-start;
  }
  .outlookForToday {
    flex: 1;
    position: relative;
    text-transform: uppercase;
  }
  .actionWrapper,
  .frameParent9 {
    display: flex;
    flex-direction: row;
  }
  .actionWrapper {
    width: 174px;
    padding: 16px 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
  }
  .frameParent9 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    color: #727376;
  }
  .b57ac673f06a4b0338a596817eb0a5Icon {
    position: relative;
    width: 31px;
    height: 31px;
    object-fit: cover;
  }
  .bitcoin,
  .btc {
    align-self: stretch;
    position: relative;
  }
  .btc {
    text-transform: uppercase;
  }
  .bitcoin {
    font-size: 14px;
    color: #727376;
  }
  .btcParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
  }
  .b57ac673f06a4b0338a596817eb0a5Parent {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 12px;
  }
  .div,
  .div1 {
    align-self: stretch;
    position: relative;
  }
  .div1 {
    color: #ce2d4f;
  }
  .parent {
    gap: 2px;
  }
  .parent,
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .button {
    border-radius: 6px;
    background-color: white;
    border: 1px solid rgba(67, 97, 238, 0.15);
    padding: 10px 16px;
    justify-content: center;
  }
  .button,
  .buttonWrapper,
  .frameParent10 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .buttonWrapper {
    padding: 0 20px;
    justify-content: flex-end;
    color: #4361ee;
  }
  .frameParent10 {
    align-self: stretch;
    justify-content: flex-start;
  }
  .frameWrapper6 {
    border-radius: 8px;
    display: flex;
    padding: 18px 0;
    align-items: flex-start;
    justify-content: center;
  }
  .frameParent8,
  .frameWrapper6,
  .frameWrapper7 {
    align-self: stretch;
    flex-direction: column;
  }
  .frameWrapper7 {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    padding: 18px 0;
    align-items: flex-start;
    justify-content: center;
  }
  .frameParent8 {
    font-size: 14px;
  }
  .frameParent7,
  .frameParent8,
  .instanceParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameParent7 {
    flex: 1;
    height: 1024px;
    overflow: hidden;
    flex-direction: column;
    padding: 24px 32px;
    box-sizing: border-box;
    gap: 30px;
    font-size: 30px;
    color: #000;
  }
  .instanceParent {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    flex-direction: row;
  }
  .cryptocurrencyList {
    position: relative;
    background-color: #EDF1F6;
    width: 100%;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 25.67px;
    color: #f8f6f8;
    font-family :"Satoshi, sans-serif";
  }
  
  .activeMenu {
    border-radius: "10px";
    background:  linear-gradient(90deg, #1877F2 0%, #519DFF 100%);
    color: white!important;
    font-weight:bold;
    /* border-right: 2px solid red; */
  }
  .activeMenu span {

    /* border-right: 1px solid blue; */
  }
  /* .activeMenu span{
    color: #4361ee!important;
    border-right: none;
  } */

 

  

