:root {
    --primary-color: #1877F2;
    --secondary-color: #9AFFF9;
    --tertiary-color: #FFF06D;
    --background-color: #f9f9f9;
    --text-color: #000000;
    --border-color: rgba(24, 119, 242, 1);
    --box-shadow-color: rgba(0, 0, 0, 0.25);
    --gradient-1: linear-gradient(91.4deg, rgba(154, 255, 249, 0.6) 18.86%, rgba(255, 240, 109, 0.6) 100.02%);
    --gradient-2: linear-gradient(180deg, rgba(24, 119, 242, 0.4) -1.89%, rgba(24, 119, 242, 0) 60.38%);
    --gradient-3: linear-gradient(91.4deg, rgba(154, 255, 249, 0.24) 18.86%, rgba(255, 240, 109, 0.24) 100.02%);
    --gradient-4: linear-gradient(91.4deg, #9AFFF9 18.86%, #FFF06D 100.02%);
    --gradient-5: linear-gradient(91.4deg, rgba(52, 152, 145, 0.6) 18.86%, rgba(176, 163, 37, 0.6) 100.02%);

}
.profile-section{
    width: 63% !important;
}

.header-tabs {
    border: 1px solid var(--border-color);
    border-radius: 3rem;
    padding: 3px 4px;
    /* max-width: 1000px; */
    margin: auto;
}

.header-tabs a {
    text-decoration: none;
    box-shadow: 0px 5px 0px 0px var(--box-shadow-color);
}

.header-tabs a .tab-text {
    padding-left: 2%;
    white-space: nowrap;
    color: var(--text-color);
    font-weight: 700;
}

.profile-tab-icon {
    padding-right: 5px;
}

.profile-tab-div {
    display: flex;
}

.profile-tab-div span {
    white-space: nowrap;
}


.my-profile-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 50px;
    /* min-width: 160px; */
    white-space: nowrap;
    text-decoration: none;
    background: var(--gradient-1);
    border: 1px solid var(--border-color);
    box-shadow: 0px 5px 0px 0px var(--box-shadow-color);
}

.my-profile-btn img,
.my-profile-btn svg {
    width: auto;
    height: auto;
    max-height: 24px;
}
.notification-section{
    cursor: pointer;
}
.notification-section span {
    font-size: xx-small;
    transform: translateX(-100%) !important;
    
}

.notification-icon {
    min-width: 25px;
    width: 9%;
}

.profile-left-section {
    gap: 10px;
    margin-right: 8px;
}

.search-section {
    margin-bottom: 1%;
}

.search-icon {
    fill: var(--primary-color);
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    position: absolute;
    left: 25px;
    width: 20px;
    height: 20px;
}

.search-container input {
    padding-left: 60px;
    width: 100%;
    height: 65px;
    border-radius: 3rem;
    border: 1px solid var(--border-color);
    background-color: transparent;
    font-size: large;
    max-width: 800px;
    color: var(--text-color);
    caret-color: var(--text-color);
}

.search-container input::placeholder {
    color: var(--text-color) !important;
    opacity: 1;
}

.reward-card {
    flex-direction: row;
    border-radius: 10px !important;
    box-shadow: 0px 4px 20px -10px var(--box-shadow-color);
    max-width: 350px;
    height: 100%;
    border: 1px solid var(--border-color);
    background: var(--gradient-2);
    padding: 3px 5px 0px 0px !important;
}


.transaction-text {
    font-weight: 700;
    font-size: 100%;
    text-decoration: none;
    margin: auto;
}

.airdrop-btn {
    background: var(--gradient-3);
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
    border-radius: 20px;
    text-decoration: none;
    gap: 15px;
    min-width: 215px;
    max-width: 223px;
}

.airdrop-btn-text {
    font-weight: 700;
    color: var(--text-color);
    line-height: 2rem;
}

.top-gainer-card {
    background: var(--gradient-3);
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
    border-radius: 20px;
    width: 45%;
    padding: 10px 10px;
}

.top-gainer-card p {
    margin: auto;
}
.top-gainer-card img{
    width: 35px;
    height: 35px;
}

.profile-headings {
    background: var(--gradient-4);
    border-radius: 10px;
    border: 1.8px solid var(--border-color);
    padding: 0.5% 2%;
}

.profile-headings h1 {
    font-size: x-large;
    font-weight: 700;
    margin: auto;
    padding: 1% 0%;
}

.profile-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.profile-table .t-head {
    background: var(--gradient-2);
    border-bottom: 5px solid var(--border-color) !important;
}

.profile-table .t-head th {
    font-weight: 700;
    padding: 12px 16px;
    border-bottom: 5px solid var(--border-color) !important;

}

.profile-table tbody tr {
    background: #FFF;
}

.profile-table tbody td {
    padding: 12px 16px;
    border: none;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    font-size: x-large;
}

.profile-table tbody tr td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left: 1px solid var(--border-color);
}

.profile-table tbody tr td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-right: 1px solid var(--border-color);
}

.profile-table .table-col-1 {
    background: var(--gradient-1);
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
    font-size: x-large;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 20px;
    gap: 5px;
}

.profile-table .btn-outline-primary:hover img {
    filter: brightness(0) invert(1);
}

.section-1 {
    margin-top: 5%;
    margin-bottom: 10%;
}

.section-2 {
    margin-top: 5%;
    margin-bottom: 10%;
}

.tab-2-7-day-summary .tab-hook{
    max-width: 360px;
    margin-top: 2%;
    margin-bottom: 2%;
}


.add-crypto-watchlist-button button {
    background: var(--gradient-1);
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
    font-size: larger;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 20px;
    gap: 40px;
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.wallet-button button {
    background: var(--gradient-1);
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
    font-size: 100%;
    color: var(--text-color);
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 20px;
    gap: 20px;
}

.profile-container-lg {
    max-width: 1200px;
    margin: 0 auto;
}

.reward-container {
    gap: 20px;
}

.profile-table-main {
    width: 100%;
    overflow-x: auto;
}

.profile-table-main table {
    width: 100%;
    white-space: nowrap;
}

.is-desktop {
    display: block !important;
}

.is-mobile {
    display: none !important;
}


/* Table card */
.sm-table-card {
    border: 1px solid rgba(24, 119, 242, 1);
    border-radius: 20px;
    padding-bottom: 2%;
    margin: 10% 0%;
}

.sm-table-heading {
    border-bottom: 5px solid rgba(24, 119, 242, 1);
    background: linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0%, rgba(24, 119, 242, 0) 100%);
    border-radius: 20px 0px 0px;

}

.sm-table-heading h6 {
    margin: 0 auto;
    font-size: 130% !important;
    font-weight: 700;
    padding: 16px 18px;
    display: inline-block;
    width: 100%;
}

.sm-table-body {
    padding: 2%;
    font-size: 100%;
}

.sm-btn-1 {
    background: var(--gradient-1);
    border-radius: 3rem;
    gap: 2%;
    color: var(--text-color);
    font-size: 100%;
    font-weight: 700;
    border: none;
    padding: 1vw 3vw;
    display: flex;
    white-space: nowrap;
    box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
}

.sm-btn-1 img {
    width: 25px;
    height: 25px;
}

.sm-table-body span {
    min-width: 45%;
    white-space: nowrap;
}

/* .input-container{
    gap: 50px;
    display: flex;
    flex-direction: row;
    
    justify-content: space-around;
} */

.address-input {
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
}

.address-input input {
    background: transparent;
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(24, 119, 242, 1);
    padding: 20px 28px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding-right: 77px;
}

.address-input button {
    position: absolute;
    right: 18px;
    /* Position inside input */
    top: 19%;
    color: rgba(24, 119, 242, 1);

    border: none;
    padding: 2% 3%;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    background: var(--gradient-1);
}

.address-input button:hover {
    background: var(--gradient-5);
    color: #000000;
    transform: none !important;
}

.left-arrow-icon {
    width: 5%;
}

@media (max-width: 992px) {
    .profile-container-lg {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .profile-headings h1 {
        font-size: 100%;
        font-weight: 700;
        margin: auto;
        padding: 1% 0%;
    }

    .is-desktop {
        display: none !important;
    }

    .is-mobile {
        display: block !important;
    }

    .input-container {
        flex-wrap: wrap;
    }

    .address-input {
        width: 100%;
    }

    .search-container input {
        font-size: 100%;
        height: 10vw;
    }

    .address-input input {
        padding: 12px 12px;
        width: 100%;
        font-size: 12px;
    }

    .address-input button {
        padding: 1% 2%;
    }

    .profile-tab-div span {
        white-space: normal;
    }
    .tab-2-7-day-summary .tab-hook .tab_hook_link{
        width: 47vw !important;
        letter-spacing: 1px !important;
        padding: 20px 10px !important;
        font-weight: 500 !important;
    }
    .add-crypto-watchlist-button button {
        font-size: 100%;
        padding: 10px 10px;
        gap: 20px;
    }
    .reward-container {
        gap: 2px;
    }
    .airdrop-btn {
        min-width: 170px;
    }
    .airdrop-btn img{
        width: 30px;
        height: 30px;
    }
    .airdrop-btn-text {
        line-height: 1rem;
        font-weight: 600;
    }
    .profile-section{
        width: 100%;
    }
}

.crypto_icon{
    width: 25px !important;
    height: 25px !important;
}