/* Define reusable variables for colors, fonts, and other constants */
:root {
    --primary-bg-color: #EDF1F6;
    --highlight-color: #1877F2;
    --accent-color: #9AFFF9;
    --secondary-accent: #FFF06D;
    --border-radius: 1rem;
    --font-size-large: 16px;
    --font-weight-bold: 700;
}

/* General Styles */
.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.main-content {
    flex-grow: 1;
    overflow: auto;
    background-color: var(--primary-bg-color);
}

.main-div {
    background-color: var(--primary-bg-color);
    width: 100%;
    min-height: 100vh;
    white-space: nowrap;
}

/* Presale Styles */
.presale .secondary-main {
    padding: 0 1%;
}

.presale .card {
    width: 100%;
    border: 1px solid #1877F2;
}

.presale .theme-bg {
    background: linear-gradient(91deg, var(--accent-color) 18.86%, var(--secondary-accent) 100.02%);
}

.presale .heading {
    text-align: center;
    width: 80%;
}

.presale .presale-form .card {
    background-color: rgba(72, 206, 255, 0.2);
}

.presale .presale-form h1,
.presale .sub-heading {
    color: #000;
    border: 1px solid var(--highlight-color);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    padding: 10px;
    border-radius: var(--border-radius);
    width: 100%;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
}

.presale .btn-theme-bg {
    background: linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(255, 255, 255, 0) 60.38%);
    background-color: #FFF;
}

.presale .price-div p {
    text-align: center;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
}

.presale .presale-form button {
    color: #000;
    border: 1px solid var(--highlight-color);
    font-size: var(--font-size-large) !important;
    font-weight: var(--font-weight-bold);
    padding: 5px 10px;
    border-radius: var(--border-radius);
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
}

.presale .button-text {
    padding: 7px 10px;
}

.presale .cypto-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-top: 5%;
}



/* .presale .cypto-btn button {
    background: linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%);
} */

.presale .cypto-btn img {
    width: 25px;
    margin-right: 10px;
}

.presale .input-div-main {
    margin-top: 5%;
}

.presale label {
    font-weight: 600;
}

.presale select,
.presale input {
    width: 100%;
    padding: 0 4px;
    border-radius: var(--border-radius);
    background: linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%);
    color: black;
    border: 1px solid var(--highlight-color);
}

.presale select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.presale .connect-wallet-btn {
    min-width: 19rem;
}

.presale .connect-wallet-btn button {
    width: 100% !important;
    min-width: 100% !important;
    height: auto !important;
    background: linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%);
    background-color: #FFF;
    padding: 6px 10px;
}

.presale .connect-wallet-btn img {
    width: 25px;
    margin-right: 30px;
}

.text-info-div {
    display: flex;
    align-items: center;
}

.info-div {
    width: 400px;
}

.text-info-div p {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    line-height: normal;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
}

/* Bottom Button Styles */
.presale .bottom-btn {
    display: flex;
    justify-content: space-between;
}

.presale .bottom-btn button {
    width: 35%;
    background: linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%);
}

.presale .bottom-signup-btn {
    display: flex;
    justify-content: center;
    padding: 0 10%;
    margin-top: 10%;
}

.presale .bottom-signup-btn button {
    min-width: 21rem;
    background: linear-gradient(91deg, var(--accent-color) 18.86%, var(--secondary-accent) 100.02%);
}

.presale .bottom-signup-btn button img {
    margin-right: 5px;
    width: 25px;
}

.presale .slider {
    width: 18rem !important;
}

/* Slider */
.css-188mx6n-MuiSlider-root {
    height: 8px !important;
}

.css-7o8aqz-MuiSlider-rail {
    background-color: #b9b9b9 !important;
    border: 1px solid #7d7d7d !important;
    border-radius: 5px !important;
}

.css-cp2j25-MuiSlider-thumb {
    background-color: rgb(255, 255, 255) !important;
    border: 8px solid currentColor !important;
    height: 28px !important;
    width: 28px !important;
}

/* Select Icon */
.select-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.select-container::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #333;
    pointer-events: none;
}

.presale .row {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.presale .active {
    background-color: #89cbff !important;
}

.presale .product-card {
    background: linear-gradient(180deg, #9dbfff66, rgba(24, 119, 242, 0.00) 60.38%);
    border: 1px solid #9e9cff66;
    border-radius: 15px;
}

/* Media Queries */
@media (max-width: 376px) {
    .presale label {
        font-size: small;
        transition: ease-in-out 0.5s;
    }

    .presale .bottom-btn button {
        width: 47%;
    }

    .text-info-div {
        display: flex;
        align-items: start;
    }

    .presale .heading {
        width: 100%;
    }

}

@media (max-width: 516px) {
    .presale .main-content {
        padding: 0 !important;
    }

    .presale .card {
        width: 100% !important;
    }

    .presale .slider {
        width: 85% !important;
    }

    .presale .info-div {
        width: 320px;
    }



}

@media (max-width: 677px) {
    .presale .presale-form button {
        font-size: 14px !important;
    }

    .presale button img {
        width: 25px !important;
        margin-right: 10px !important;
    }

    .presale .farming-page .cards-container {
        font-family: 'Satoshi', Arial, sans-serif;
        display: block;
        justify-content: center;
        gap: 0px;

    }

    .presale .cypto-btn {
        display: flex;
        flex-wrap: wrap; /* Ensures items wrap to the next row if needed */
        gap: 10px; /* Space between columns */
        justify-content: space-between;
    }
    .presale .cypto-btn button {
        width: 48%; /* Each column takes 50% width */
        padding: 5px;
        text-align: center;
    }

}
wui-text {
    color: #494949 !important;
}

@media screen and (max-width: 1137px) {
    .farming-page .card {
        width: 332px;
    }
}


/* testing */


.farming-page {
    font-family: 'Satoshi', Arial, sans-serif;
    text-align: center;
    background-color: #D2FD8B;
    padding: 50px;
    text-wrap: break-word;
    width: 100%;
}

.farming-page h1 {
    font-family: 'Satoshi', Arial, sans-serif;
    font-size: 630%;
    margin-bottom: 10px;
}

.farming-page p {
    font-family: 'Satoshi', Arial, sans-serif;
    font-size: 18px;
    margin-bottom: 30px;

}

.farming-page .cards-container {
    font-family: 'Satoshi', Arial, sans-serif;
    display: flex;
    justify-content: center;
    gap: 0px;

}


.farming-page .card {
    border-radius: 20px;
    border: none;
    background: #FFF;
    box-shadow: 0px 24px 50px -12px rgba(45, 54, 67, 0.12);
    font-family: 'Satoshi', Arial, sans-serif;
    padding: 3% 2%;
    width: 332px;
    text-align: left;
    background: url('../../../public/images/presale/frame-card-bg.png') no-repeat center center;
    /* Image path */
    background-size: cover;
}

.card-icon {
    font-family: 'Satoshi', Arial, sans-serif;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.card p {
    font-family: 'Satoshi', Arial, sans-serif;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}

.card-button {
    font-family: 'Satoshi', Arial, sans-serif;
    background: var(--Onclick-icons, #1877F2);
    color: #ffffff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
}

.card-button:hover {
    font-family: 'Satoshi', Arial, sans-serif;
    background-color: #0056b3;
}


.odd-card {
    background-color: #fffcec;
    border: 1px solid #dddddd;
    border-radius: 5px;

}

.even-card {
    background-color: #fffcec;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.mid-div {
    background-color: #D2FD8B;
    width: 18px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #0909c6;
}

.number p {
    background: #cbdff9;
    border-radius: 13rem;
    width: fit-content;
    padding: 2px 8px;
    font-weight: 600;
}