/* Sidebar Container */
.sideBarTopMenu {
    height: calc(100vh - 360px);
    overflow-y: auto !important;
    scrollbar-gutter: stable both-edges; /* Keeps space for scrollbar */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for Safari */
    scrollbar-width: thin; /* Firefox */
    
    scrollbar-color: #497dbd transparent; /* Firefox (thumb, track) */
    white-space: nowrap;
    padding-left: 20px;
    
}

/* Ensure Safari Shows Scrollbar */
@supports (-webkit-touch-callout: none) {
    .sideBarTopMenu {
        scrollbar-width: auto !important;
        overflow-y: scroll !important;
    }
}



/* Custom Scrollbar for Chrome, Safari, Edge */
.sideBarTopMenu::-webkit-scrollbar {
    width: 8px;
}

.sideBarTopMenu::-webkit-scrollbar-track {
    background: transparent; /* Keeps it clean */
}

/* Scrollbar Thumb */
.sideBarTopMenu::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #497dbd, #d4e8ff);
    border-radius: 50px;
}

/* Hover Effect */
.sideBarTopMenu::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #497dbd, #d4e8ff);
}

/* Thin Vertical Line */
.sideBarTopMenu::before {
    content: "";
    position: absolute;
    top: 114px;
    right: 3px;
    width: 4px;
    height: calc(100vh - 384px);
    background: rgba(73, 125, 189, 0.5);
    z-index: 1;
}

@media screen and (max-width: 900px) {
    .sideBarTopMenu::before {
        display: none;
    }
}



/* Small Screens: Hide Scroll */
@media (max-width: 900px) { 
    .sideBarTopMenu {
        height: 100%;
        overflow-y: hidden;
    }
}
