/* Base styles */
.crypto-page {
    font-family: 'Satoshi', sans-serif;
    height:100%;
    padding:20px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  
  .search-sort-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #f2f2f2;
    gap: 21%;
    margin-top: -30px;
    width: 96%;
    margin-left: 2%;
  }
  
  .search-input {
    width: 350px;
    max-width: 30%;
    border: 1px solid #000;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .sort-by {
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;
  }
  .layout {
    display: flex;
  }
  
  .sidebar {
    width: 20%;
    /* Additional styling for the sidebar */
  }
  
  .content {
    flex: 1;
    /* Ensures the content takes up the remaining space */
  }
  
  .predictions-table {
    width:96%;
    border-collapse: collapse;
    height: 100%;
    background-color: white!important;
    margin-left: 2%;
  }
  
  .predictions-table th, .predictions-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  th {
    font-weight: 250;
  }
  
  .predictions-table td {
    font-size: 14px;
  }
  
  .predictions-table th {
    background-color: #ffffff;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .full-width-button {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border: none;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: 40px;  
  }
  
  .button-logo {
    height: 3%;
    width: 15%;
  }
  
  .clickable {
    color: green;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .pending {
    padding: 5px !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: #0147AB !important;
    font-size: 11px !important;
    font-weight: bold !important;
    background-color: #e5f3ff !important;
  }
  
  .modal-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 1%;
  }
  
  .modal-table th, .modal-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    color: black;
    font-weight: 500;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 300px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .sort-options label {
    display: block;
    margin-bottom: 10px;
  }
  
  .sort-by {
    position: relative;
    cursor: pointer;
    user-select: none;
  }
  
  .sort-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 200px;
    font-size: 14px;
  }
  
  .sort-dropdown div {
    padding: 8px;
    cursor: pointer;
  }
  
  .sort-dropdown div:hover {
    background: #f0f0f0;
  }
  
  
  
  /* Mobile view adjustments */
  @media (max-width: 900px) {
    .search-sort-row {
    display: flex!important;
    flex-direction: column!important;
    width:100%!important;
    align-items: center!important;
    margin-bottom: 10px!important;
    background-color: #f2f2f2!important;
    gap: 18%!important;
    margin-top: -30px!important;
    padding-top: 2%!important;
    padding-left: 3%!important;
    margin-left: -0.5%!important;
    }
  
    .search-input {
      width: 100%!important;
      max-width: 100%!important;
      margin: 0 0 10px 0!important;
      margin-right: 2.5%!important;
      padding: 10px;
      background-color: white!important;
      color: black!important;
      margin: 15px;
    }
  
    .sort-by {
      justify-content: center!important;
      margin-bottom: 20px!important;
    }
  
    .predictions-table {
      display: block!important;
      overflow-x: auto!important;
      width:100%!important;
      margin-left:0%!important;
      table-layout: fixed;
    }
    .forecaster-column {
    width: 300px; /* Set your desired width here */
    overflow-wrap: break-word; /* Ensure text wraps properly */
    word-wrap: break-word; /* For older browsers */
  }
  
    .full-width-button {
      margin-bottom: 30px!important;
      height: 80px!important;
    }
  
    .button-logo {
      width: 70%!important;
      height: auto!important;
    }
  
    .modal-content {
      width: 90%!important;
    }
  
    /* Adjust font sizes */
    body, p, .predictions-table td, .predictions-table th, .sort-dropdown div, .dropdown-content a {
      font-size: calc(100% - 5%)!important;
    }
    .load-more{
      width: 100px!important;
      margin:auto auto!important;
      justify-content: center!important;
    }
    
  }
  