.frameChild {
    position: relative;
    border-radius: 6px;
    width: 33.63px;
    height: 33.63px;
  }
  .crypto {
    position: relative;
    letter-spacing: -0.01em;
  }
  .frameContainer,
  .frameGroup,
  .frameWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .frameGroup {
    align-items: center;
    gap: 15.17px;
  }
  .frameContainer,
  .frameWrapper {
    align-self: stretch;
    padding: 13px 0;
    align-items: flex-start;
  }
  .frameWrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    flex-direction: column;
    padding: 18px 26px;
  }
  .frameIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameParent2,
  .frameWrapper1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .frameParent2 {
    flex: 1;
    gap: 10px;
  }
  .frameWrapper1 {
    align-self: stretch;
    border-radius: 8px 0 0 8px;
    background-color: rgba(67, 97, 238, 0.15);
    border-right: 1px solid #4361ee;
    padding: 14px;
    color: #4361ee;
  }
  .leaderBoard {
    flex: 1;
    position: relative;
  }
  .frameWrapper2 {
    align-self: stretch;
    border-radius: 8px 0 0 8px;
    flex-direction: row;
    padding: 14px;
    align-items: center;
  }
  .frameParent1,
  .frameWrapper2,
  .frameWrapper5 {
    display: flex;
    justify-content: flex-start;
  }
  .frameParent1 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .frameWrapper5 {
    border-radius: 8px;
    background-color: #ce2d4f;
    width: 240px;
    flex-direction: row;
    padding: 14px;
    box-sizing: border-box;
    align-items: center;
    color: #fff;
  }
  .frameDiv,
  .frameParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .frameDiv {
    align-self: stretch;
    flex: 1;
    padding: 26px 0 26px 26px;
    justify-content: space-between;
    font-size: 14px;
    color: #727376;
  }
  .frameParent {
    border-radius: 0 10px 10px 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-sizing: border-box;
    width: 292px;
    height: 1024px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-start;
  }
  .frameIcon5,
  .text {
    position: relative;
  }
  .frameIcon5 {
    width: 18px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .search {
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.11);
    padding: 13px 23px;
    align-items: center;
    gap: 10px;
  }
  .search,
  .search1,
  .searchParent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .search1 {
    border-radius: 6px;
    background-color: #4361ee;
    padding: 13px 23px;
    align-items: center;
    color: #fff;
  }
  .searchParent {
    align-items: flex-start;
    gap: 25px;
    opacity: 0;
    text-align: center;
    font-size: 14px;
  }
  .portfolioParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .frameIcon6 {
    position: relative;
    width: 114px;
    height: 114px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .youDontHave {
    margin: 0;
  }
  .youDontHaveContainer {
    position: relative;
    font-weight: 600;
  }
  .frameParent9 {
    flex-direction: column;
    gap: 1px;
  }
  .frameParent8,
  .frameParent9,
  .search2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search2 {
    border-radius: 6px;
    background-color: #4361ee;
    width: 146px;
    height: 53px;
    flex-direction: row;
    padding: 13px 23px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
  }
  .frameParent8 {
    align-self: stretch;
    flex: 1;
    flex-direction: column;
    gap: 43px;
    text-align: center;
    font-size: 34px;
    color: #2d2c2d;
  }
  .frameParent7,
  .instanceParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameParent7 {
    flex: 1;
    height: 1024px;
    overflow: hidden;
    flex-direction: column;
    padding: 24px 32px;
    box-sizing: border-box;
    gap: 30px;
    font-size: 30px;
    color: #000;
  }
  .instanceParent {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    flex-direction: row;
  }
  .portfolio {
    position: relative;
    background-color: #EDF1F6;
    width: 100%;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 25.67px;
    color: #f8f6f8;
  }