/* styles.css */


.subscription-container .subscription-column {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    margin-top: 5%;
}
.subscription-container-2{
    gap: 4px;
}
.subscription-column.custom-col {
    width: 100%; 
    padding: 10px 1px;
}

.mobile-view{
    display: none !important;
}
.subscription-container-main{
    width: 100%;
}

.subscription-container .subscription-button {
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding: 6px 1px;
    border-radius: 50px;
    margin-bottom: 5px;
    width: 100%;
}
th {
    vertical-align: top;  /* Aligns content at the top */
    padding: 0;  /* Optional: Remove any padding if needed */
}

.subscription-column h2{
    color: rgb(0, 0, 0);
    padding-bottom: 10px;
    font-weight: 700;
    margin-left: 10px;
}

.subscription-container .subscription-button-free {
    border: 1px solid #007bff;
    color: #007bff;
}

.subscription-container .subscription-button-primary {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

.subscription-container .table-responsive {
    margin-top: 2rem;
    overflow-x: auto; /* Disable horizontal scrolling */
}
.subscription-container table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto; /* Allows horizontal scrolling if needed, without showing the scrollbar */
    margin-bottom: 53px;
}

.subscription-container th,
.subscription-container td {
    text-align: center;
    padding: 0.5rem;
}

.subscription-container th {
    border: none;
    /* background-color: rgba(237, 244, 255, 1); */
    /* border-radius: 100px 100px 0 0; */
}

.subscription-container td {
    border: none;
}

.subscription-container td:nth-child(1) {
    background-color: #bbd7fc;
    border-right: 10px solid white;
    border-left: 10px solid white;
    width: 30%;
}

.subscription-container td:nth-child(2),
.subscription-container td:nth-child(3),
.subscription-container td:nth-child(4) {
    background-color: rgba(237, 244, 255, 1);
    border-right: 10px solid white;
    width: 20%;;
}

.subscription-container td:last-child {
    background-color: rgba(237, 244, 255, 1);
    width: 20%;;
}

.subscription-container .fw-bold {
    text-align: left;
    padding-left: 1rem;
}

.subscription-container .check-icon {
    color: green;
}
.top-row td{
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: 41px;
    padding: 0px;
    margin: 0px;
}
.bottom-row td{
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 41px;
    padding: 0px;
    margin: 0px;
}

@media screen and (max-width: 900px) {
    .subscription-container{
        max-width: 100% !important;
        margin-bottom: 10px !important;
    }
    .mobile-view{
        display: block !important;
    }
    .header-button button{
        min-height: 55px;
        min-width: 86px;
        width: 90%;
        border-radius: 30px;
        font-size: 13px;
    }
    .subscription-container td:nth-child(1) {
        background-color: #bbd7fc;
        border-right: 4px solid white;
        border-left: 1px solid white;
        width: 30%;
    }
    
    .subscription-container td:nth-child(2),
    .subscription-container td:nth-child(3),
    .subscription-container td:nth-child(4) {
        background-color: rgba(237, 244, 255, 1);
        border-right: 4px solid white;
        width: 20%;;
    }
    
}