.frameChild {
  position: relative;
  border-radius: 6px;
  width: 33.63px;
  height: 33.63px;
}

.crypto {
  position: relative;
  letter-spacing: -0.01em;
}

.frameContainer,
.frameGroup,
.frameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.frameGroup {
  align-items: center;
  gap: 15.17px;
}

.frameContainer,
.frameWrapper {
  align-self: stretch;
  padding: 13px 0;
  align-items: flex-start;
}

.frameWrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  flex-direction: column;
  padding: 18px 26px;
}

.frameIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

}

.frameParent2,
.frameWrapper1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.frameParent2 {
  flex: 1;
  gap: 10px;
}

.frameWrapper1 {
  align-self: stretch;
  border-right: 1px solid #4361ee;
  padding: 14px;
  color: #4361ee;
}

.leaderBoard {
  flex: 1;
  position: relative;
}

.frameWrapper2 {
  align-self: stretch;
  border-radius: 8px 0 0 8px;
  flex-direction: row;
  padding: 14px;
  align-items: center;
}

.frameParent1,
.frameWrapper2,
.frameWrapper5 {
  display: flex;
  justify-content: flex-start;
}

.frameParent1 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.frameWrapper5 {
  border-radius: 8px;
  background-color: white;
  width: 240px;
  flex-direction: row;
  padding: 14px;
  box-sizing: border-box;
  align-items: center;
  color: #fff;
}

.frameDiv,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.frameDiv {
  align-self: stretch;
  flex: 1;
  padding: 26px 0 26px 26px;
  justify-content: space-between;
  font-size: 14px;
  color: #727376;
}

.frameParent {
  border-radius: 0 10px 10px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-sizing: border-box;
  width: 292px;
  height: 1024px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
}

.frameIcon5,
.text {
  position: relative;
}

.frameIcon5 {
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.search {
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 13px 23px;
  align-items: center;
  gap: 10px;
}

.search1 {
  border-radius: 6px;
  background-color: #4361ee;
  padding: 13px 23px;
  align-items: center;
  color: #fff;
}

.searchParent {
  align-items: flex-start;
  gap: 25px;
  opacity: 0;
  text-align: center;
  font-size: 14px;
}

.portfolioParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.frameIcon6 {
  position: relative;
  width: 114px;
  height: 114px;
  overflow: hidden;
  flex-shrink: 0;
}

.youDontHave {
  margin: 0;
}

.youDontHaveContainer {
  position: relative;
  font-weight: 600;
}

.frameParent9 {
  flex-direction: column;
  gap: 1px;
}

.frameParent8,
.frameParent9,
.search2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search2 {
  border-radius: 6px;
  background-color: #4361ee;
  width: 146px;
  height: 53px;
  flex-direction: row;
  padding: 13px 23px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
}

.frameParent8 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: 43px;
  text-align: center;
  font-size: 34px;
  color: #2d2c2d;
}

.frameParent7,
.instanceParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameParent7 {
  flex: 1;
  height: 1024px;
  overflow: hidden;
  flex-direction: column;
  padding: 24px 32px;
  box-sizing: border-box;
  gap: 30px;
  font-size: 30px;
  color: #000;
}

.instanceParent {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
}

.portfolio {
  position: relative;
  background-color: #EDF1F6;
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: 25.67px;
  color: #f8f6f8;
  font-family: "var(--font-family)";
}

.editPortfolio1 {
  position: relative;
  font-weight: 600;
}

.frameIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.editPortfolioParent,
.primaryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.primaryButton {
  border-radius: 6px;
  background-color: #fff;
  width: 33px;
  height: 33px;
  justify-content: center;
  cursor: pointer;
}

.editPortfolioParent {
  align-self: stretch;
  justify-content: space-between;
  font-size: 22px;
}

.nameOfCrypto {
  position: relative;
}

.buysellAllUnitsWrapper,
.nameOfCryptoWrapper {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  align-items: center;
}

.nameOfCryptoWrapper {
  flex: 1;
  justify-content: flex-start;
}

.buysellAllUnitsWrapper {
  width: 171px;
  box-sizing: border-box;
  justify-content: flex-end;
}

.frameGroup {
  border-radius: 8px;
  background-color: #fff;
  width: 606px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  color: #727376;
}

.b57ac673f06a4b0338a596817eb0a5Icon {
  position: relative;
  width: 31px;
  height: 31px;
  object-fit: cover;

}

.bitcoin,
.btc {
  align-self: stretch;
  position: relative;
}

.btc {
  text-transform: uppercase;
}

.bitcoin {
  font-size: 14px;
  color: #727376;
}

.btcParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  margin-left: 5px;
}

.b57ac673f06a4b0338a596817eb0a5Parent {
  flex: 1;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 12px;
}

.div {
  align-self: stretch;
  position: relative;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonChild {
  position: relative;
  width: 15px;
  height: 1.36px;
}

.button {
  border-radius: 6px;
  background-color: white;
  border: 1px solid rgba(67, 97, 238, 0.15);
  box-sizing: border-box;
  width: 47px;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonItem {
  position: relative;
  width: 15px;
  height: 15px;
}

.buttonParent,
.frameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonParent {
  padding: 0 20px;
  justify-content: flex-end;
  gap: 10px;
}

.frameContainer {
  align-self: stretch;
  justify-content: flex-start;
}

.frameWrapper {
  border-radius: 8px;
  width: 606px;
  padding: 18px 0;
  box-sizing: border-box;
  justify-content: center;
}

.frameDiv,
.frameParent,
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.frameDiv {
  border-radius: 8px;
  background-color: #fff;
  width: 606px;
  padding: 18px 0;
  box-sizing: border-box;
  justify-content: center;
}

.frameParent {
  justify-content: flex-start;
}

.save {
  position: relative;
}

.saveWrapper {
  align-self: stretch;
  border-radius: 8px;
  background-color: #4361ee;
  display: flex;
  flex-direction: row;
  padding: 20px 22px;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.editPortfolio {

  border-radius: 9px;
  background-color: #EDF1F6;
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding: 22px 24px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: var(--font-family);
}

.non_responsiveTable {
  width: 100%;
}

.non_responsiveTable td .tdBefore {
  display: none;
}

.btn {
  background-color: white;
}

.non_responsiveTable td,
.responsiveTable th {
  /* background-color: red; */
  border-bottom: none;
  /* height: ; */
  /* padding: 10px; */
  /* font-weight: 300; */


}

/* .article-link{
    color:rgb(87, 116, 205);
     text-decoration: underline; 
  }
     */

/* .article-link:hover {
   cursor: pointer;
   color: blue; 
  } */

.non_responsiveTable th,
.non_responsiveTable td {
  padding: 3px 5px 3px 5px;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

@media screen and (max-width: 40em) {
  /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */

  .maindiv {
    background-color: #EDF1F6;

  }

  .responsiveTable th {
    margin-left: 2vw;
    margin-right: 2vw;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.11); */
    display: block;
    text-align: start;
    /* background-color: red; */
  }

  .non_responsiveTable th,
  .non_responsiveTable td {
    padding: 3px 5px 3px 5px;
  }

  .responsiveTable td {
    margin-left: 2vw;
    margin-right: 2vw;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.11); */
    text-align: start;
    display: block;
    text-align: start;
  }

  /*   
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable div,
    .responsiveTable tr {
     display: block;
      text-align: start;
    }
   */
  .lasttablerow {
    text-align: start;
    margin-left: 2vw;
    margin-right: 2vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);

  }

  /* .table_content{
      margin-left :2vw;
      margin-right: 2vw;
      border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    } */

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }

  .responsiveTable tbody tr {
    /* border: 1px solid rgb(182, 171, 171); */
    padding: .25em;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: white;
    /* background-color: red; */
    /* box-shadow: #000; */
    /* box-shadow: 10px 10px; */
  }

  .maindiv {
    background-color: #EDF1F6;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
    /* border-bottom: 1px solid black; */
  }

  .lastRow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    border: 3px solid black;
  }

  .portfoliobutton {
    background-color: white;
    border-radius: 6px;
    border-style: solid;
    border-color: var(--blueactive, rgba(67, 97, 238, 0.15));
    border-width: 1px;
    padding: 10px 16px 10px 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }

  .cryptocurrencyListHeaderSearch {
    width: 100%;
    border: 2px solid black;

  }

  .main_account {
    background-color: #EDF1F6;
    width: 100%;
    height: 100vh;
    display: block;
  }

  .form {
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    padding-right: 15px;
  }

}

.table_mobile {
  font-weight: 100;
  font-size: 0.8em;
}

.loadingButton {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 10px;
}

@media only screen and (min-width: 40em) {
  div.table_mobile {
    display: none;

  }

  div.windowheader {
    display: none;
  }
}

/* styles.css */

.chip-label {
  color: var(--CW-Blue-700, #1B35B8);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 75% */
  letter-spacing: 0.16px;
}

.chip {
  border-radius: 100px;
  background: var(--CW-Blue-100, #C5CFFD);
}

.home-small-container {
  min-height: 100vh;
  flex-grow: 1;
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  overflow-x: hidden;
  padding: 1%;
}
