.footer-container{
    margin-bottom: 0px;
    position: relative;
    bottom: 0;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    border-radius: 5px;
    white-space: nowrap;
}
.footer-container a{
    text-decoration: none;
}

.footer-link-text{
    border-right: 2px solid #6c757d;
}

@media (max-width: 576px) {
    .footer-container {
        margin: 2rem 0rem 75px 0px;  /* Increase margin on smaller screens */
        padding: 10px;        /* Add padding for smaller screens */
    }

    .footer-container .footer-links {
        font-size: 12px;  /* Adjust font size for small screens */
        text-align: center; /* Center the links */
    }

    .footer-container .footer-text {
        font-size: 9px;  /* Adjust font size for smaller screens */
    }
}

@media (max-width: 900px) {
    .footer-container {
        margin: 2rem 0rem 75px 0px; /* Slightly larger margin for tablets */
    }

}

/* Media query for large screens (larger than 768px) */
/* @media (min-width: 769px) {
    .footer-container {
        margin: 2rem 0rem 75px 0px;  
    }

} */