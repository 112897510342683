.mainContainer {
    font-family:'Satoshi', Arial,sans-serif;
    /* border-top-right-radius: 0.5rem;
    background-color: #f8f6f8;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    text-align: center;
    padding: 2rem; */

  }
  .mainContainer_div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    padding: 10px;
  }

.imageContainer_login{
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_first{
  width: 80%;
  height: 80%;
}
.vCenter{
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.loginContainer{
background-color: red;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
  border-top-right-radius: 0.5rem;
    background-color: #f8f6f8;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    text-align: center;
    padding: 2rem;
    margin: 10px;
    width: 100%;
}
.login_text{
  width: 300px;
}
.login_button_div{
  background-color: white;
  width: 300px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.googleGLogo51{
  margin: 10px;
}

/* Apply a mobile-first approach for screens smaller than 40em */
@media screen and (max-width: 40em) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center;    /* Center items horizontally */
    min-height: 100vh;      /* Ensure full viewport height */
    padding: 10px;          /* Padding around the content */
  }

  .mainContainer_div {
    display: flex;
    flex-direction: column;
    align-items: center;    /* Center items horizontally */
    text-align: center;     /* Center text inside the container */
    padding: 20px;          /* Padding around the content */
    
  }

  .imageContainer_login {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .vCenter {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center;     /* Center items horizontally */
    width: 100%;
    height: auto;            /* Adjust height as needed */
  }

  .loginContainer {
    width: 100%;
    max-width: 400px;       /* Set a maximum width for better alignment */
    padding: 1rem;
    margin: 0 auto;         /* Center the container horizontally */
    text-align: center;     /* Center text inside the container */
  }

  .login_text {
    width: 100%;
    margin-bottom: 1rem;    /* Space below the text */
  }

  .login_button_div {
    width: 100%;
    margin-top: 1rem;       /* Space above the button */
  }
}
