.logo_div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.main_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   max-width: 80vw;
   min-width: 300px;
}
.update_profile_text{
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: normal; 
}
.main_profile{
    display: flex;
    width: 372px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px; 
}
.main_profile_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch; 
}

.main_profile_form label {
    display: flex;
    padding: 20px 22px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    background-color: white;
  }

.main_profile_form {
    display: flex;
    padding: 20px 22px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch; 

}
.form_group{
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
