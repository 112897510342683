.welcome {
    font-family: 'Satoshi', Arial, sans-serif;
    background-color: #eaf4fb;
    min-height: 100vh;
    text-align: center;
    
  }
  
  .header {
    display: flex;
    font-family: 'Satoshi', Arial, sans-serif;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: white;
  }
  
  
  
  .nav-links a {
    font-family: 'Satoshi', Arial, sans-serif;
    margin: 0 15px;
    text-decoration: none;
    color:   #221262;
    font-size: 17px;
    font-weight: 500;
  }
  
  .connect-wallet {
    font-family: 'Satoshi', Arial, sans-serif;
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 1000px;
    background: var(--Onclick-icons, #1877F2);    
    color: white;
    padding: 14px 25px;
    border: none;
    cursor: pointer;
    margin-right:50px;
  }
  
  .top-portfolios {
    font-family: 'Satoshi', Arial, sans-serif;
    margin-top: 0px;
   
  }

  h1{
    font-family: 'Satoshi', Arial, sans-serif;
    color: #000;
    font-size: 62px;
    font-style: normal;
    font-weight: 400;
  }
  
  .portfolio-grid {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    font-family: 'Satoshi', Arial, sans-serif;
    margin-right:50px;
  }
  
  .portfolio-item {
    
    margin: 0 60px;
    padding: 20px;
    border-radius: 10px;
    width: 200px;
    font-family: 'Satoshi', Arial, sans-serif;
    position: relative;
  }
  
  .portfolio-item img {
    font-family: 'Satoshi', Arial, sans-serif;
    border:2px solid black;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 0px;
  }
  
  .portfolio-details {
    font-family: 'Satoshi', Arial, sans-serif;
    text-align: center;
  }
  
  .portfolio-name {
    font-family: 'Satoshi', Arial, sans-serif;
    display: inline-flex;
    padding: 6px 11px;
    align-items: center;
    border-radius: 6.667px;
    background: #000;
    color: white;
  }
  .base1{
    font-family: 'Satoshi', Arial, sans-serif;
    width: 269px;
    height: 152px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #1877F2 0%, #DFEDFF 100%);
  }
  
  .base2{
    font-family: 'Satoshi', Arial, sans-serif;
    width: 269px;
    height: 152px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #1877F2 0%, #DFEDFF 100%);
  }
  .base3{
    font-family: 'Satoshi', Arial, sans-serif;
    width: 269px;
    height: 152px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #1877F2 0%, #DFEDFF 100%);
  }
  .portfolio-rank {
    font-family: 'Satoshi', Arial, sans-serif;
    font-size: 2em;
    font-weight: bold;
    color: #0056b3;
    position: absolute;
    top: 90px;
    right: 0px;
  }
  
  .portfolio-roi {
    font-family: 'Satoshi', Arial, sans-serif;
    border-radius: 6px;
    background: #2A82F4;    
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color:white;
  }
  
  .timeframe-buttons {
    font-family: 'Satoshi', Arial, sans-serif;
    margin-top: 20px;
  }
  
  .timeframe-button {
    font-family: 'Satoshi', Arial, sans-serif;
    background-color: #eaf4fb;
    border: 1px solid #0056b3;
    color: #0056b3;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .timeframe-button.active {
    font-family: 'Satoshi', Arial, sans-serif;
    background-color: #0056b3;
    color: white;
  }

  /* Container for the Portfolios */
.portfolio-container1 {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the gap for better spacing */
  margin: 20px;
}

/* Individual Portfolio Item */
.portfolio-item1 {
  background: white;
  border: 1px solid #EEE;
  border-radius: 10px;
  padding: 20px; /* Increase padding for better spacing */
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.portfolio-item1:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

/* Content within Each Portfolio Item */
.portfolio-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header of Each Portfolio Item */
/* Container for the Portfolios */
.portfolio-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 20px; /* Adjust the gap for better spacing */
  margin: 0px;
}

/* Individual Portfolio Item */
.portfolio-item1 {
  background-color: white; /* Example background color */
  padding: 20px;
  width: 100%;
  border: 1px solid #EEE; /* Example border */
  border-radius: 8px; /* Example border-radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.portfolio-item1:hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhance shadow on hover */
}

/* Content within Each Portfolio Item */
.portfolio-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Header of Each Portfolio Item */
.portfolio-header1 {
  display: flex;
  align-items: center; /* Center align vertically */
  justify-content: center; /* Align items to the left */
  width: 100%;
  margin-bottom: 10px; /* Add spacing below header */
}

.portfolio-header1 img {
  margin-right: 15px;
}

.portfolio-header1 span {
  
 
  font-family: 'Satoshi', Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

/* Divider Between Header and Actions */
.portfolio-divider1 {
  width: 100%;
  height: 2px;
  background: #EDEDED;
  margin: 10px 0;
}

/* Actions Section */
.portfolio-actions1 {
  display: flex;
  justify-content: center;
  margin: 10px 0; /* Adjust margin for spacing */
  width: 100%;
}

.portfolio-actions1 button {
  background-color: white;
  border: 2px solid #1877F2;
  color: #1877F2;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.portfolio-actions1 button:hover {
  background-color: #1877F2;
  color: white;
}

.farming-page {
  font-family: 'Satoshi', Arial, sans-serif;
  text-align: center;
  background-color: #D2FD8B;
  padding: 50px;
}

.farming-page h1 {
  font-family: 'Satoshi', Arial, sans-serif;
  font-size: 36px;
  margin-bottom: 10px;
}

.farming-page p {
  font-family: 'Satoshi', Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
}

.cards-container {
  font-family: 'Satoshi', Arial, sans-serif;
  display: flex;
  justify-content: center;
  gap: 0px;
  
}

.card {
  border-radius: 25px;
background: #FFF;
box-shadow: 0px 24px 50px -12px rgba(45, 54, 67, 0.12);
  font-family: 'Satoshi', Arial, sans-serif;
  padding: 24px;
  width: 25%;
  height:80%;
  text-align: left;
  
}

.card-icon {
  font-family: 'Satoshi', Arial, sans-serif;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.card p {
  font-family: 'Satoshi', Arial, sans-serif;
  color: #000;
font-size: 16px;
font-style: normal;
font-weight: 500;
margin-bottom:10px;
}

.card-button {
  font-family: 'Satoshi', Arial, sans-serif;
  background: var(--Onclick-icons, #1877F2);
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.card-button:hover {
  font-family: 'Satoshi', Arial, sans-serif;
  background-color: #0056b3;
}



/* .wise-image {
 position: relative;
 z-index: 10;
}

.shadow-image {
  position: relative;
  z-index:0;
}

.wise1 {
 top: 95px;
 left:70px;
 transform: rotate(15deg);
}

.wise2 {
  top: 115px;
  left:-70px;
}

.wise3 {
  top: 175px;
  left:-50px;
}

.wise4 {
  top: 170px;
  left:-210px;
}

.wise5 {
  top: 230px;
  left:-190px;
}

.wise6 {
 right:-520px;
 top:90px;
 transform: rotate(-15deg);
}

.wise7 {
  right:-550px;
 top:110px;
  
}
.wise8 {
  right:-440px;
  top:165px;
}
.wise9 {
  right:-500px;
  top:160px;
}
.wise10 {
  right:-400px;
  top:220px;
}

.shadow1 {
  top:125px;
  left:-400px;
}

.shadow2 {
  top:130px;
  left:-650px;
  transform: rotate(-10deg);
}

.shadow3 {
  top:190px;
  left:250px;
  transform: rotate(-10deg);
}
.shadow4 {
  top:185px;
  left:-860px;
  transform: rotate(-10deg);
}
.shadow5 {
  top: 210px;
  left:-940px;
  transform: rotate(290deg);
  transform: scaleY(-1);
} */

  .spark1{
    position:relative;
    top:60px;
    left:-220px;
  }
  .spark2{
    position:relative;
    top:-30px;
    left:-240px;
  }
  .spark3{
    position:relative;
    top:-50px;
    left:-200px;
  }
  .spark4{
    position:relative;
    top:70px;
    right:-240px;
  }
  .spark5{
    position:relative;
    top:30px;
    right:-240px;
  }
  .spark6{
    position:relative;
    top:-20px;
    right:-200px;
  }

 