
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  font-family: 'Satoshi', sans-serif;
  --Gray-6: #F2F2F2;
  --cw-blue-900: #0E2287;
  --Gray-6: #F2F2F2;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --Gray-4: #BDBDBD;
  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  color: var(--Black, #000);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-family: --var(font-family);
  margin: auto;
  line-height: normal;
 background-color: #EDF1F6;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.MuiTypography-h5,
.MuiTypography-body2 {
  text-align: left;
}

.list__sidebar__bar__terms {
  position: relative;
  top: 8rem;
}

.list__sidebar__bar__about {
  position: relative;
  top: 9rem;
}


.list__sidebar__bar__privacy {
  position: relative;
  top: 9rem;
}

.togglebuttonSell {
  color: #ffffff !important;
  background-color: grey;
}

.togglebuttonBuy {
  color: #ffffff !important;
  background-color: blue;
  text-align: center;
  padding-left: 3rem;
}

.togglebuttonSell {
  color: #ffffff !important;
  background-color: grey;
  padding-left: 3rem;
  text-align: center;
}

#outlined-required {
  text-align: center;
}

.portfolioUnits {
  position: relative;
  left: 11rem;
}

.MuiInputBase-root {
  border-radius: 6px;
  position: relative;
}

.modal__editPortfolioParent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.modal__editPortFolioParent__primaryButton {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: #ffffff;
  color: #f8f6f8;
  width: 33px;
  height: 33px;
  justify-content: center;
  cursor: pointer;
}

.modal__editPortfolioParent {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.modal__editPortfolioParent__title {
  width: 125px;
  height: 22px;
 
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1rem;
}

.modal__editPortfolio {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-sizing: border-box;
  padding: 22px 24px;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: --var(--font-family);
}

.saveWrapper {
  border-radius: 4px;
  background-color: #4361ee;
  display: flex;
  padding: 8px 16px 8px 16px;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  height: 40px;
  width: 344px;
  gap: 8px;
  position: relative;
  left: 10rem;
}

.CardOpener {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 50px;
 border: none;
}
.CardOpener1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
 
 border: none;
}
.card__container {
  display: flex;
  
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 400px;
}
.card__container1 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 400px;
}
.card__container__row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.card__container__row2 {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  /* gap: 220px; */
 
  border: 1px solid var(--Gray-5);
  border-radius: var(--borderRadius, 4px);
background: var(--CW-Blue-0, #F5F7FF);
  /* background-color: red; */
  /* height: 52px; */
  width: 100%;
  /* background-color: red; */
}

.row2__left{
  width: 100%;
}
.row2__left__bitcoinLogo {
  width: 24px;
  height: 24px;
}

.row2__left__text__btc {
  color: var(--Portfolio-Color-Black-2, #2D2C2D);

  text-align: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  /* width: 31px; */
  height: 16px;
  position: absolute;
  margin-left: 3px;
}

.row2__left__text__bitcoin {
  color: var(--Portfolio-Color-Silver-text, #727376);
  text-align: center;
  font-family: var(--font-family);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  height: 16px;
  /* width: 31px; */
  position: absolute;
  margin-top: 2rem;
  margin-left: 3px;
}

.row2__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 40px;
  height: 36px;
  gap: 4px;
}

.row2__right__text__unit {
  color: var(--Portfolio-Color-Black-2, #2D2C2D);

  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  width: 40px;
  height: 16px;
}
.placeholder-text {
  font-size: 12px; /* Decrease the font size of the placeholder text */
  text-align: center; /* Align the placeholder text to the center */
}

.upload-container {
  margin-bottom: 30px;
}

.upload-label {
  font-size: 14.736px;
  font-weight: 500;
}

.upload-wrapper {
  position: relative;
  width: 100%;
  height: 47.891px;
}

.upload-input {
  background: #ECECEC;
  margin-top: -10px;
  border: 1px solid #ECECEC;
  border-radius: 11.052px;
  width: 100%;
  height: 52px;
  position: relative;
}

.upload-icon {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 42.319px;
}

.row2__right__text__unitcount {
  color: var(--Portfolio-Color-Silver-text, #727376);

  font-family: var(--font-family);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  width: 8px;
  height: 16px;
  position: absolute;
  margin-top: 20px;
}

.row2__left {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: rgba(0, 0, 0, 1);
  width: 60px;
  height: 32px;
  font-family: var(--font-family);
}


.row2__left__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 1);
  width: 31px;
  height: 32px;
}


.card__container__row3 {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  gap: 1rem;
  background-color: #ffffff;
}

.card__container__row3__r1,
.card__container__row3__r2,
.card__container__row3__r3,
.card__container__row3__r4 {
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* gap: 5rem; */
}


.card__container__row3 {
  display: flex;
  padding: 8px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-5);
}

.card__container__row3__r1__text {
  width: 110px;
  height: 16px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 0.5rem;
}


.card__container__row3__r2__text {
  height: 16px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 0.5rem;
}


.card__container__row3__r1__input {
  display: flex;
  width: 152px;
  padding: 6px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid var(--Gray-4);
  background: var(--Gray-6);
  color: var(--Black);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.card__container__row3__r2__input {
  display: flex;
  width: 152px;
  padding: 6px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid var(--Gray-4);
  background: var(--Gray-6);
  color: var(--Black);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}



.card__container__row3__r3__text,
.card__container__row3__r4__text {
  margin: 6px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
}

.card__container__row3__r3__date {
  background: var(--Gray-6, #F2F2F2);
  width: 152px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0;
  color: white;
  box-sizing: border-box;
  cursor: text;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.buttonToggle {
  display: flex;
  align-items: flex-start;
  width: 152px;
  height: 32px;
}

/* .buttonToggleBuy,
.buttonToggleSell {
  display: flex;
  align-items: center;
}

.buttonToggleBuy {
  border-radius: 6px 0 0 6px;
  border: 1px solid var(--Gray-4, #bdbdbd);
  background: var(--Gray-6);
}

.buttonToggleBuyActive {
  color: white;
  background: #0e2287;
}

.buttonToggleSell {
  border-radius: 0 6px 6px 0;
  border: 1px solid var(--Gray-4, #bdbdbd);
  background: var(--Gray-6);
}

.buttonToggleSellActive {
  color: white;
  background: #0e2287;
} */
.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  width: inherit;
  height: 32px;
}

.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  width: inherit;
  height: 32px;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  appearance: textfield;
}

.MuiTableRow-root,
.MuiTableCell-root,
.MuiTableContainer-root {
  border: none;
}

.btnLightBlue {
  text-transform: capitalize;
  border-radius: 6px;
  border: 1px solid var(--Blue-active, rgba(67, 97, 238, 0.15));
  background: var(--Blue-active, rgba(67, 97, 238, 0.15));
}

 .CryptocurrencyListHeader {
  padding-Bottom: 1rem;
  margin-top: -4rem;
  color: var(--Black, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color:black;
}
.pageheader{
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  /* background-color: red; */
}

.CryptocurrencyListHeaderSearch {
  padding-bottom: 1rem;
  margin-top: -4rem;
} 
.portfoliotableviewHeader,
.cryptotableviewHeader {
  color : #BDBDBD !important;
   font-weight: 500 !important;
   width: 15vw;
 }

 @media screen and (max-width: 40em) {
 .card__container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width:90vw;
  }
  .maindiv{
    background-color:#EDF1F6;
  
  }
 }