.whatsappImage20230721At1 {
    position: relative;
    width: 1003px;
    height: 512px;
    /* background-image: url(images/whatsapp-image-20230721-at-1115-1@3x.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
 
@media only screen and (min-width: 40em) {
    div.windowheader{
      display: none;
    }
 

  }
  
